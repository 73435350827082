<template>
	<section class="container">
		<div>
			<h1>Import medarbejdere</h1>
		</div>
		<div class="row mt-2 mb-2">
			<div class="col-12">
				<hr />
			</div>
		</div>
		<div class="row justify-content-center">
			<div class="col-auto">
				<h2>
					<a href="/files/importMedarbejderSkabelon_v9.xlsx" target="_blank"><u>DOWNLOAD EXCEL SKABELON HER</u></a>
				</h2>
			</div>
		</div>

		<div class="row">
			<div class="col-12">
				<h3>
					<b>Guide til import</b>
				</h3>
				<div class="row mt-5">
					<div class="col-12">
						<ol>
							<li>Download excel skabelon</li>
							<li>Udfyld data for hvert medarbejdere</li>
							<li>Upload <b>excel fil (xlsx)</b> til denne side</li>
							<li>
								HUSK at gruppen skal have tilføjet pension/forsikring aftale først, hvis du vil overføre data for medarbejderens forsikring/pension
							</li>
						</ol>
					</div>
				</div>
			</div>
			<div class="col-lg-6 col-12 mx-auto mb-3">
				<b-form-file @change="readFile" accept=".xlsx"> </b-form-file>
			</div>
			<xlsx-read :file="file">
				<xlsx-json @parsed="onComplete"> </xlsx-json>
			</xlsx-read>
		</div>
		<div class="row" v-if="employees.length != 0">
			<b-form @submit.prevent="onSubmit">
				<div class="col-12 mb-3">
					<BaseTable :items="employees" :fields="fields" :fixed="true" :small="true">
						<template v-for="(i, index) in fields" v-slot:[`cell(${i.key})`]="data">
							<component :is="i.type" v-model="data.value" v-on:input="updateField(data.value, data.index, i.key)" :key="index"> </component>
						</template>

						<template #cell(group)="data">
							<InputDropdown
								v-model="data.item.group"
								:options="groupsName"
								:required="true"
								@input.native="getFirstPolicy(data.item, $event.target.value)"
							></InputDropdown>
						</template>

						<template #cell(insuranceId)="data">
							<InputDropdown
								v-model="data.item.insuranceId"
								:options="companyInsurances(data.item.group)"
								:required="companyInsurances(data.item.group).length > 0"
								v-bind:show="data.item.group"
							></InputDropdown>
						</template>

						<template #cell(pensionId)="data">
							<InputDropdown
								v-model="data.item.pensionId"
								:options="companyPensions(data.item.group)"
								:required="companyPensions(data.item.group).length > 0"
								v-bind:show="data.item.group"
							></InputDropdown>
						</template>

						<template #cell(show_details)="row">
							<b-button size="sm" @click="row.toggleDetails" class="mr-2"> {{ row.detailsShowing ? 'Skjul' : 'Vis' }} detajler </b-button>
						</template>

						<template #row-details="row">
							<template v-for="(i, key) in row.item" v-bind="key">
								<b-card v-if="getDanishName(key)">
									<b>{{ getDanishName(key) }}</b
									>: {{ i }}
								</b-card></template
							>
							<b-button size="sm" @click="row.toggleDetails">Hide Details</b-button>
						</template>

						<template #cell(status)="data">
							{{ status[data.index] }}
						</template>
					</BaseTable>
				</div>
				<div class="col-12 mt-3">
					<BaseActionButton v-bind:clicked="clicked" v-bind:disabled="clicked" type="submit" id="signupButton">
						{{ $t('global.create') }}
					</BaseActionButton>
				</div>
			</b-form>
		</div>
	</section>
</template>

<script>
import BaseTable from '@/modules/global/components/BaseTable'
import CompanyAdvisor from '@/modules/global/components/AdvisorDropdown.vue'
import InputDropdown from '@/modules/global/atomComponents/InputDropdown'
import InputText from '@/modules/global/atomComponents/InputText.vue'
import InputNumber from '@/modules/global/atomComponents/InputNumber.vue'
import importKeys from '@/resource/importKeys.json'
import exportKeys from '@/resource/exportKeys.json'

import { XlsxRead, XlsxJson } from 'vue-xlsx'
export default {
	name: 'EmployeeImport',
	components: {
		BaseTable,
		CompanyAdvisor,
		InputText,
		InputNumber,
		InputDropdown,
		XlsxRead,
		XlsxJson,
	},
	data() {
		return {
			file: null,
			clicked: false,
			group: '',
			employees: [],
			status: [],
			exportKeysFormatted: { ...exportKeys },
			fields: [
				{
					key: 'group',
					label: 'Gruppe',
				},
				{
					key: 'insuranceId',
					label: 'Forsikringsaftale',
				},
				{
					key: 'pensionId',
					label: 'Pensionsaftale',
				},
				{
					key: 'email',
					type: 'InputText',
					label: 'Email',
					sortable: true,
				},
				{
					key: 'phone',
					type: 'InputText',
					label: 'Telefon',
					sortable: true,
				},
				{
					key: 'firstName',
					type: 'InputText',
					label: 'Fornavn',
					sortable: true,
				},
				{
					key: 'lastName',
					type: 'InputText',
					label: 'Efternavn',
					sortable: true,
				},
				{
					key: 'cpr',
					type: 'InputText',
					label: 'CPR',
					sortable: true,
				},
				{
					key: 'salary',
					type: 'InputNumber',
					label: 'Grundløn pr mnd',
					sortable: true,
				},
				{
					key: 'supplementarySalary',
					type: 'InputNumber',
					label: 'Tillægsløn pr mnd',
					sortable: true,
				},
				{
					key: 'healthInsuranceStartDate',
					type: 'InputText',
					label: 'Sundhedsforsikring start dato',
				},
				{
					key: 'pensionStartDate',
					type: 'InputText',
					label: 'Pension start dato',
				},
				{
					key: 'employmentDate',
					type: 'InputText',
					label: 'Ansættelsedato',
				},
				{
					key: 'status',
				},
				{
					key: 'show_details',
					label: 'Vis detajler',
				},
			],
		}
	},
	async mounted() {
		this.$parent.$emit('updateLoader')
		this.this.$emit('updateLoader')
	},
	methods: {
		getFirstPolicy(employee, groupName) {
			var insurances = this.companyInsurances(groupName)
			var pensions = this.companyPensions(groupName)
			let insuranceId = undefined
			let pensionId = undefined

			if (insurances.length == 1) {
				insuranceId = insurances[0]
			}
			if (pensions.length == 1) {
				pensionId = pensions[0]
			}
			employee.insuranceId = insuranceId
			employee.pensionId = pensionId
			return employee
		},
		updateField(data, index, keyString) {
			let keyArray = keyString.split('.')
			let key = keyArray.pop()
			let object = this.employees[index]
			for (let i in keyArray) {
				object = object[keyArray[i]]
			}
			object[key] = data
		},
		parseToNumber(value) {
			if (value) {
				if (value.includes(',')) {
					value = value.replace(',', '.')
				}
			}
			return Number(value)
		},
		async onSubmit() {
			this.clicked = true
			this.toast('Import påbegyndt', 'Alle medarbejdere er ved at blive importeret til virksomheden', true)
			var success = 0
			for (let index = 0; index < this.employees.length; index++) {
				try {
					var element = this.employees[index]
					// Set group

					var groupFound = this.groups.find((group) => group.id === element.group)
					if (!groupFound) {
						var group = this.groups.find((item) => {
							return item.name == element.group
						})
						element['group'] = group.id
					}

					element['insuranceId'] = this.getCompanyInsuranceIdByName(element['insuranceId'], element['group'])
					element['pensionId'] = this.getCompanyPensionIdByName(element['pensionId'], element['group'])
					// Check if salary and supplementary salary is set otherwise set it to null
					if (!element['salary']) {
						element['salary'] = null
					}
					if (!element['supplementarySalary']) {
						element['supplementarySalary'] = null
					}
					await this.$store.dispatch('companyVuex/importEmployee', {
						data: element,
						id: this.companyId,
					})
					this.$set(this.status, index, 'SUCCESS')
					success++
				} catch (err) {
					console.log(err.message)
					this.$set(this.status, index, 'Failed: ' + err.message)
					this.clicked = false
					//this.toast('Error', 'Noget gik galt: ' + err.message, false)
				}
			}
			this.clicked = false
			if (success > 0) {
				this.toast('Import færdig', 'Alle medarbejdere er blevet importeret til virksomheden', true)
			} else {
				this.toast('Import færdig', 'Ingen medarbejdere blev importeret til virksomheden', false)
			}
			this.$store.dispatch('companyVuex/reloadCompany', this.companyId)
			this.$store.dispatch('user/reload')
		},
		async onComplete(results) {
			var tmp = results
			var arr = []
			var allKeys = {
				...importKeys,
			}
			// Remove last row if it is empty
			if (tmp[tmp.length - 1].CVR == '') {
				tmp.splice(tmp.length - 1, 1)
			}
			for (let index = 0; index < tmp.length; index++) {
				var element = tmp[index]
				var employee = {}
				var keys = Object.keys(element)
				// Generate keys
				for (let index = 0; index < keys.length; index++) {
					try {
						var key = keys[index]
						var realKey = allKeys[key]
						var data = element[key]
						console.log(data)
						employee[realKey] = decodeURIComponent(encodeURIComponent(data))
					} catch (err) {
						console.log(err)
						console.log(realKey, data)
						this.toast('Import error', 'Der er gået galt med bruger (' + index + ')' + ': ' + err.message, false)
					}
				}
				if (element['Landekode'] == '45') {
					element['Landekode'] = '+45'
				}
				if (employee['phone']) {
					employee['phone'] = element['Landekode'] + element['Telefon']
				}
				if (employee['healthInsuranceStartDate']) {
					employee['healthInsuranceStartDate'] = new Date(Math.round((employee['healthInsuranceStartDate'] - 25569)*86400*1000)).toISOString().split('T')[0];
				}
				if (employee['pensionStartDate']) {
					employee['pensionStartDate'] = new Date(Math.round((employee['pensionStartDate'] - 25569)*86400*1000)).toISOString().split('T')[0];
				}
				if (employee['employmentDate']) {
					employee['employmentDate'] = new Date(Math.round((employee['employmentDate'] - 25569)*86400*1000)).toISOString().split('T')[0];
				}
				// Populate data
				try {
					if (employee['cvr'] != this.getCompanyCVR) {
						this.toast(
							'CVR nummer ikke ens',
							'CVR nummeret på medarbejder (' +
								employee['firstName'] +
								' ' +
								employee['lastName'] +
								')' +
								' stemmer ikke overens med virksomhedens CVR nummer',
							false
						)
					}
					if (employee['group']) {
						employee = this.getFirstPolicy(employee, employee.group)
					}
					arr.push(employee)
					console.log(employee)
					this.status.push('Afventer import')
				} catch (err) {
					console.log(err)
					this.toast('Import error', 'Der er gået galt med bruger (' + index + ')' + ' ' + employee['email'] + ': ' + err.message, false)
				}
			}
			this.employees = arr
		},
		readFile(event) {
			this.file = event.target.files ? event.target.files[0] : null
		},
		getDanishName(key) {
			return this.exportKeysFormatted[key] ?? null
		},
		getCoverSalary(salary, supplementarySalary) {
			return (salary + supplementarySalary) * 12 // get salary annunally
		},
		companyInsurances(groupName) {
			var groupId = this.groups.find((group) => group.name == groupName)?.id
			var companyInsurances = this.company.insurances.filter((companyInsurance) => companyInsurance.groups.includes(groupId))
			return companyInsurances.map((companyInsurance) => {
				return companyInsurance.insurance.name
			})
		},
		companyPensions(groupName) {
			var groupId = this.groups.find((group) => group.name == groupName)?.id
			var companyPensions = this.company.pensions.filter((companyPension) => companyPension.groups.includes(groupId))
			return companyPensions.map((companyPension) => {
				return companyPension.pension.name
			})
		},
		getCompanyPensionIdByName(name, groupId) {
			var pensions = this.company.pensions.filter((companyPension) => {
				return companyPension.pension.name == name && companyPension.groups.includes(groupId)
			})
			return pensions[0]?.id
		},
		getCompanyInsuranceIdByName(name, groupId) {
			var insurances = this.company.insurances.filter((companyInsurance) => {
				return companyInsurance.insurance.name == name && companyInsurance.groups.includes(groupId)
			})
			return insurances[0]?.id
		},
	},
	computed: {
		getCompanyName() {
			return this.company?.companyInfo?.name ?? ''
		},
		getCompanyCVR() {
			return this.company?.companyInfo?.cvr ?? ''
		},
		companyId() {
			return this.$route.params.companyId
		},
		suppliers() {
			return this.$store.getters['supplier/suppliers']
		},
		groupsName() {
			return this.groups.map((item) => {
				return item.name
			})
		},
		groups() {
			return this.$store.getters['companyVuex/company'].groups
		},
		company() {
			return this.$store.getters['companyVuex/company']
		},
	},
}
</script>
<style lang="scss">
td {
	padding-left: 0.1em !important;
	padding-right: 0.1em !important;
}
</style>
